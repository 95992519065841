<template>
  <div class="pagetips" v-if="isShowTip">
    <div class="topbox">
      <img class="opera-point" src="../../assets/downLoad/point.png" alt="">
      <div>呜呜，被拦截了!请点击右上角，
        <br>
        选择“在浏览器中打开”</div>
    </div>

    <!-- <div class="tip-head">如何下载智汇学堂APP？</div>
    <div class="tip-one">
      <div>1.点击</div>
      <img src="../../assets/downLoad/clickMore.png" alt="">
    </div>
    <div class="tip-two">
      <div>2.再点击</div>
      <div class="tip-two-cont">
        <img src="../../assets/downLoad/browserLogo.png" alt="">
        <span>在默认浏览器中打开</span>
      </div>
    </div>
    <div class="tip-foot">即可打开智汇学堂APP</div> -->
  </div>
</template>

<script>
export default {
  name: 'pagetips',
  data () {
    return {
      isShowTip: false
    }
  },
  methods: {
    showEvent () {
      this.isShowTip = !this.isShowTip
    }
  }
}
</script>

<style lang='scss'>
  .pagetips {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(22, 22, 22, 0.8);
    box-sizing: border-box;
    color: #FFFFFF;
    z-index: 100;
    .topbox {
      display: flex;
      flex-direction: column;
      align-items: end;
      text-align: center;
      margin-right: 10.53vw;

      .opera-point {
        width: 12.67vw;
        height: 11.08vh;
        margin: 1.85vh 0 0.74vh;
      }
      >div {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
      }

    }

  }
</style>
