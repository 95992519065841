<template>
  <div class="ordinaryDown">
    <div class="ordinary-head">
      <img class="topbg" src="@/assets/downLoad/bg.png" alt="">
      <img class="headBg" src="@/assets/downLoad/logo.png" alt="">
      <img class="downloadNow" src="@/assets/downLoad/mainText.png" alt="">
    </div>
    <div class="ord-content">
      <div class="button" @click="downEvent">尚未下载？点击免费下载APP</div>
      <!-- <div class="tips">尚未下载？点击免费下载</div> -->
      <!-- <div class="tips" @click="openApps"> 已安装, 直接打开APP</div> -->
      <!-- {{ hasApp?"安装":"未安装" }} -->
      <!-- <div class="ord-cont-head">
        <img class="textImg" src="@/assets/downLoad/text.png" alt="">
      </div>
      <div class="ord-item" v-for="(item, index) in imgData" :key="index">
        <img :src="item" alt="">
      </div> -->
    </div>
    <!-- <div style="height: 75px;"></div> -->
    <pagetips ref="pagetips"></pagetips>
  </div>
</template>

<script>
import pagetips from '../commonModule/pagetips'

export default {
  name: 'ordinaryDown',
  components: {
    pagetips
  },
  data () {
    return {
      imgData: [
      ],
      downurls: '',
      type: 1,
      hasApp: true, // 假设已安装 app
      iosAPPlink: 'https://api.zhihuischool.cn/.well-known/apple-app-site-association',
      androidAPPlink: 'android://com.czh.zhihuischool/open'
    }
  },
  // watch: {
  //   '$store.state.deviceType'(val) {
  //     alert(val);
  //     let type = val == 1 ? 2 : 1;
  //     this.getNewVersionEvent(type);
  //   }
  // },
  mounted () {
    console.log(wx, 'wx')
    // window.location.href = 'android://com.czh.zhihuischool/open'
    // alert(this.$store.state.deviceType)
    const type = this.$store.state.deviceType == 1 ? 2 : 1
    this.type = this.$store.state.deviceType
    if (this.$store.state.deviceType < 4) {
      this.getNewVersionEvent(type)
      // this.openApp()
    } else {
      // this.downurls = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.czh.zhihuischool'
      // console.log(this.$refs.pagetips);

    }
  },
  methods: {
    // getShareInfo() {
    //   wx.config({
    //       debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //       appId: res.data.appId, // 必填，公众号的唯一标识
    //       timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
    //       nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    //       signature: res.data.signature, // 必填，签名
    //       jsApiList: [
    //         "updateAppMessageShareData",
    //         "updateTimelineShareData"
    //       ] // 必填，需要使用的 JS 接口列表
    //     });
    //     wx.ready(() => {
    //       var shareData = {
    //         title: "每日新闻",
    //         desc: "2022年12月20日21:47:55每日新闻",
    //         link: window.location.href,
    //         imgUrl: "https://blogobs.88688.team/blog/l-logo-y.jpg"
    //       };
    //       //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
    //       wx.updateAppMessageShareData(shareData);
    //       //自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
    //       wx.updateTimelineShareData(shareData);
    //     });
    //     //错误了会走 这里
    //     wx.error(function (res) {
    //       console.log("微信分享错误信息", res);
    //     });
    // },
    openApp (isNeedLink = false) {
      if (this.$store.state.deviceType < 4) {
        const userAgent = navigator.userAgent
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        const startTime = new Date().getTime()
        if (/iPhone|iPod|iPad/i.test(userAgent)) {
          // iframe.src = this.iosAPPlink
          setTimeout(() => {
            console.log('ios跳转', this.downurls)

            const endTime = new Date().getTime()
            if (endTime - startTime < 2000) {
              this.hasApp = false
              if (isNeedLink) {
                window.location.href = this.downurls
              }
            }
            document.body.removeChild(iframe)
          }, 1000)
        } else if (/android/i.test(userAgent)) {
          // iframe.src = this.androidAPPlink

          setTimeout(() => {
            console.log('Android跳转', this.downurls)

            const endTime = new Date().getTime()
            if (endTime - startTime < 2000) {
              this.hasApp = false
              if (isNeedLink) {
                window.location.href = this.downurls
              }
            }
            document.body.removeChild(iframe)
          }, 1000)
        }
      } else {
        if (this.type == 4 || this.type == 5 || this.type == 6 || this.type == 7) {
          this.$refs.pagetips.showEvent()
        }
      }
    },
    getNewVersionEvent (type) {
      this.$request.getNewVersion({ type }).then(res => {
        console.log(res, '版本信息')
        if (res.data.code == 0) {
          this.downurls = res.data.data.downurls
        }
      })
    },
    handleEvent () {
      // if (this.isDownLoad) {
      //   this.downEvent()
      // } else {
      this.openApp(true)
      // }
    },
    downEvent () {
      if (!this.downurls) {
        this.Info('暂时无法下载')
        return
      }
      // window.location.href = this.downurls;
      window.location.replace(this.downurls)
    },
    openApps () {
      if (this.type == 1) {
        // window.location.href = 'https://api.zhihuischool.cn/.well-known/apple-app-site-association'
        window.location.href = this.iosAPPlink
      } else if (this.type == 2) {
        window.location.href = this.androidAPPlink
      } else if (this.type == 4 || this.type == 5 || this.type == 6 || this.type == 7) {
        this.$refs.pagetips.showEvent()
      }
    }
  }
}
</script>

<style lang='scss'>
  .ordinaryDown {
    width: 100vw;
    height: 100%;
    overflow: scroll;
    .ordinary-head {
      width: 100vw;
      height: 41.15vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .topbg{
        width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
      }
      .headBg {
        width: 32vw;
        margin: 5.17vh auto 2.65vh;
      }
      .downloadNow {
        width: 60vw;
        margin: 0 auto;
      }
    }
    .ord-content {
      width: 100%;
      padding-bottom: 75px;
      padding: 0 7.5vw;
      height: 7.14vh;
      box-sizing: border-box;
      .button {
        background: #4773EB;
        border-radius: 1.546667rem;
        margin-top: 17.3vh;
        height: 7.14vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 19px;
        color: #FFFFFF;
      }
      .tips {
        text-align: center;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #6E6E6E;
        opacity: 0.8;
        margin-top: 2.03vh;

      }
    }
  }
</style>
